.messageInput {
    height: 6rem;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}
