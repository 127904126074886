.newReleasesGrid {
    list-style-type: none;
    /*overflow: hidden;*/
    padding: 1px 0;
    display: flex;
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 2rem;
    justify-content: space-between;
}
.newReleasesGrid:after {
    content: '';
    flex: auto;
}

.newReleaseItem {
    display: inline-block;
}
