* {
    margin: 0;
    padding: 0;
    zoom: 1;
}

body,
html {
    font-family: 'WeblySleek UI', 'Segoe UI', 'Helvetica Neue', Arial, sans-serif;
    font-size: 13.5px;
    background: #e0e0e0;
    /*background: rgb(248, 248, 248);*/
    -webkit-font-smoothing: antialiased;
}

/*.noscroll {
     position: fixed;
  overflow-y: scroll;
  width: 100%;
}*/

.noscroll body {
    overflow: hidden !important;
}

#root {
    min-height: 100%;
    position: relative;
}

*:not(html, body)::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}
*:not(html, body)::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgba(175, 175, 175, 0.2);
}
*:not(html, body)::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(160, 160, 160, 0.45);
}
*:not(html, body)::-webkit-scrollbar-thumb:active {
    background-color: rgba(160, 160, 160, 0.85);
}
*:not(html, body)::-webkit-scrollbar-track:active {
    background-color: rgba(175, 175, 175, 0.3);
}
