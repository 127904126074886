/************************************************************************************/
/* border-radius */

.circle {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
}

.rounded {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    overflow: hidden;
}

.top-rounded {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    overflow: hidden;
}

/************************************************************************************/
/* colors */

.text-gray {
    color: #969696 !important;
}

.text-light-gray {
    color: #e0e0e0 !important;
}

.text-dark-gray {
    /*color: #888 !important;*/
    color: #666 !important;
}

.text-blue {
    color: #66b2e6 !important;
}

.text-dark-blue {
    color: #09c !important;
    color: #1ba1c7 !important;
}

.bg-white {
    background-color: #fff;
}

.bg-off-white {
    background-color: rgb(248, 248, 248);
}

.bg-gradient-opacity-white {
    background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0.8));
}

.bg-gray {
    background-color: #e0e0e0;
}

/************************************************************************************/
/* font-sizes */

.fs-95 {
    font-size: 0.95rem;
}

.fs-96 {
    font-size: 0.96rem;
}

.fs-100 {
    font-size: 1rem;
}

.fs-105 {
    font-size: 1.05rem;
}

.fs-110 {
    font-size: 1.1rem;
}

.fs-120 {
    font-size: 1.2rem;
}

.fs-135 {
    font-size: 1.35rem;
}

.fs-160 {
    font-size: 1.6rem;
}

.fs-200 {
    font-size: 2rem;
}

.fs-250 {
    font-size: 2.5rem;
}

/************************************************************************************/
/* font-weights */

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

/************************************************************************************/
/* margins */

.ms-50 {
    margin-inline-start: 0.5rem;
}

.ms-75 {
    margin-inline-start: 0.75rem;
}

.ms-100 {
    margin-inline-start: 1rem;
}

.ms-150 {
    margin-inline-start: 1.5rem;
}

.me-35 {
    margin-inline-end: 0.35rem;
}

.me-50 {
    margin-inline-end: 0.5rem;
}

.me-75 {
    margin-inline-end: 0.75rem;
}

.me-100 {
    margin-inline-end: 1rem;
}

.me-200 {
    margin-inline-end: 2rem;
}

.me-700 {
    margin-inline-end: 7rem;
}

/* MT */

.mt-15 {
    margin-top: 0.15rem;
}

.mt-25 {
    margin-top: 0.25rem;
}

.mt-50 {
    margin-top: 0.5rem;
}

.mt-75 {
    margin-top: 0.5rem;
}

.mt-100 {
    margin-top: 1rem;
}

.mt-150 {
    margin-top: 1.5rem;
}

/* MB */

.mb-50 {
    margin-bottom: 0.5rem;
}

.mb-75 {
    margin-bottom: 0.75rem;
}

.mb-100 {
    margin-bottom: 1rem;
}

.mb-150 {
    margin-bottom: 1.5rem;
}

/* ML */

.ml-050 {
    margin-left: 0.5rem;
}

.ml-100 {
    margin-left: 1rem;
}

.ml-150 {
    margin-left: 1.5rem;
}

.ml-200 {
    margin-left: 2rem;
}

/************************************************************************************/
/* defaults */

a {
    color: inherit;
    cursor: pointer;
    transition-duration: 0.25s;
    transition-property: background-color, color;
}

a:link,
a:visited,
a:active {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

button {
    font-size: inherit;
    cursor: pointer;
    transition-duration: 0.25s;
    transition-property: background-color, color;
}
button:disabled {
    cursor: default;
    opacity: 0.7;
}

input,
label,
select,
textarea {
    font: inherit;
    font-size: inherit;
    color: inherit;
    background: #fff;
    color: #000;
    padding: 2px;
    border-radius: 0;
    transition-duration: 0.25s;
    transition-property: background-color, border, color;
    -webkit-transition-duration: 0.25s;
    -webkit-transition-property: background-color, border, color;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    /*appearance: none;
    -webkit-appearance: none;*/
}

input,
select,
textarea {
    border: 1px solid #c7ccd3;
    border: 1px solid #dbe2e5;
    width: 100%;
    padding: 0.4rem;
    border-radius: 5px;
}

input:focus,
select:focus,
.form-control:focus,
textarea:focus {
    border: 1px solid #4d90fe;
    outline: none;
}

textarea {
    color: #969696;
    /*width: 100%;*/
    padding: 0.5rem;
    resize: vertical;
    width: 100%;
}

textarea:focus {
    color: Black;
}

/************************************************************************************/
/* common classes */

.hidden {
    display: none;
}

.center-cropped-img {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    overflow: hidden;
}

.lazy-load {
    background: rgba(130, 130, 130, 0.2);
    background: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(8%, rgba(130, 130, 130, 0.2)),
        color-stop(18%, rgba(130, 130, 130, 0.3)),
        color-stop(33%, rgba(130, 130, 130, 0.2))
    );
    background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
    background-size: 800px 100px;
    animation: loadingAnimation 1.5s infinite ease-out;
}

.img-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.65);
    color: #ccc;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 10;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.l-shadow {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

.noscroll {
    position: fixed;
    overflow-y: scroll;
    width: 100%;
}

.nowrap {
    white-space: nowrap;
}

/************************************************************************************/
/* Animation */

.scale-trans {
    transition: -webkit-transform 0.25s;
    transition: transform 0.25s;
    transition:
        transform 0.25s,
        -webkit-transform 0.25s;
}

.scale-trans:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}

@-webkit-keyframes loadingAnimation {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

@keyframes loadingAnimation {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

.spin {
    -webkit-animation: spin 2s linear infinite;
    -moz-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
