.container {
    display: inline-block;
    vertical-align: top;
}

.photoLink {
    color: inherit;
    cursor: pointer;
    transition-duration: 0.25s;
    transition-property: background-color, color;
    display: inline-block;
    text-decoration: none;
    border-radius: 50%;
    border: 1px solid #ccc;
    padding: 5px;
}

.profileDetails {
    vertical-align: top;
    text-align: center;
    padding: 10px 0;
    overflow: hidden;
    text-overflow: ellipsis;
}

.profileName {
    white-space: nowrap;
}

.inlineView {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 0.5rem;
    font-weight: 500;
}
