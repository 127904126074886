.usersLikes {
    display: inline-block;
}

.usersLikes button {
    background: none;
    display: inline-block;
    border: 0;
    color: inherit;
    line-height: 100%;
}

.green {
    color: #3dc84f !important;
}

.red {
    color: red !important;
}
