.title {
    border-bottom: 1px solid #c8c8c8;
    font-weight: 500;
    font-size: 1.75em;
    padding-bottom: 8px;
    margin-bottom: 7px;
}

.title1 {
    font-size: 2.25rem;
    font-weight: 700;
}
.title2 {
    font-size: 2rem;
    font-weight: 600;
}
.title3 {
    font-size: 1.85rem;
}
.title4 {
    font-size: 1.75rem;
}

.title a:hover {
    /*text-decoration: none;*/
    color: #09c;
}

.centered {
    text-align: center;
}
