.PlaylistInfo {
    font-size: 1.9rem;
    font-weight: 500;
    bottom: 1rem;
    position: absolute;
    width: 100%;
    cursor: pointer;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    color: #fff;
    text-shadow: 0 0 1em #000;
    text-align: center;
}

.playlistImgOverlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.4);
    display: block;
    color: #fff;
    cursor: pointer;
    transition-duration: 0.25s;
    transition-property: background-color, color;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.playlistImgOverlay:hover {
    background-color: rgba(0, 0, 0, 0.6);
}
