.userCommentContainer {
    width: 100%;
    font-size: 1.05em;
    display: flex;
    justify-content: flex-start;
    padding: 0.75rem 0;
    margin: 0.75rem 0;
    line-height: 1.75rem;
}

.userCommentContainer:not(:first-child) {
    border-top: 1px solid #e2e2e2;
}

.userCommentContainer button {
    background: none;
    border: 0;
    color: inherit;
}

.userInputComment {
    width: 100%;
}

.userInputComment textarea {
    padding: 0.5rem;
}

.guestUserCommentContainer {
    padding: 1rem;
    background-color: #f0f3f6;
}
