.recommendationOverlay {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    color: #ccc;
    width: 100%;
    height: 100%;
    z-index: 10;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.recommendationLink {
    position: absolute;
    bottom: 0.75rem;
    left: 0;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
    color: #eaeaea;
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    line-height: 2rem;
    white-space: normal;
    box-sizing: border-box;
    text-decoration: none;
}
