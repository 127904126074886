.chartTable {
    width: 100%;
    border: 0;
    border-spacing: 0;
    vertical-align: middle;
}

.chartTable tr:nth-child(even) {
    background-color: #f5f5f5;
}

.chartTable tr:hover {
    background-color: #a7dbff;
}

.chartTable tr td {
    height: 2.7em;
    font-size: 1.05em;
}

.chartTable tr td:nth-child(1) {
    width: 4%;
    min-width: 2rem;
    text-indent: 0.4rem;
}

.chartTable tr td:nth-child(2) {
    width: 48%;
}

.chartTable tr td:nth-child(3) {
    background-color: #fff;
    border-bottom: 1px solid #fff;
}

.chartTable tr:last-child td {
    border-bottom: none;
}
