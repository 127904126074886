.userInfoContainer {
    font-size: 1.05em;
    text-align: left;
    width: 100%;
    display: flex;
    column-gap: 2.4rem;
}

.userName {
    font-size: 2.25rem;
    font-weight: 500;
}

.userMusicStatistics {
    margin: 1.5rem 0;
}

.sideBySide {
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 1rem;
}

@media screen and (max-width: 820px) {
    .sideBySide {
        grid-template-columns: auto;
        align-items: center;
        grid-row-gap: 1rem;
    }
}

@media screen and (max-width: 720px) {
    .userInfoContainer {
        text-align: center;
        flex-direction: column;
    }
}
