.newReleasesGrid {
  list-style-type: none;
  overflow: hidden;
  padding-bottom: 1px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 2rem;
  row-gap: 2rem;
}
.newReleasesGrid:after {
  content: "";
  flex: auto;
}

.newReleaseItem {
  display: inline-block;
}
