.listContainer > li {
    list-style: none;
}

.listContainer > li:not(:last-child) {
    border-bottom: 1px solid rgb(221, 221, 221);
}

.itemSpacer {
    /*padding-bottom: 0.6rem;
    margin-bottom: 0.6rem;*/
    padding: 0.6rem 0.5rem;
}

.listContainer > li > a,
.listContainer > li > div {
    width: 100%;
}

.listContainer > li > a {
    text-decoration: none;
}

.listContainer > li:hover .itemContent {
    background-color: #e6f4ff;
    background-color: #e8edf1;
    background-color: #f5f6f7;
}

/*.listContainer > li:hover {
    background-color: #e8edf1;
}*/

.selectedItem,
.selectedItem:hover {
    background-color: rgb(209 221 233) !important;
    /* background-color: #6aa4d1;
    color: #fff;*/
}

.itemContent {
    display: grid;
    justify-content: space-between;
    align-items: center;
    grid-template-columns: min-content min-content 1fr min-content;
    box-sizing: border-box;
}

.itemInnerContent {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.45rem;
    /*min-width: 14rem;*/
}

.itemInnerContent * {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.smallImage {
    width: 65px;
    height: 65px;
}

.mediumImage {
    width: 80px;
    height: 80px;
}

.largeImage {
    width: 100px;
    height: 100px;
}
