.footer {
    background-color: #282828;
    color: #d7d7d8;
    text-align: center;
    left: 0;
    height: 1.5em;
    line-height: 1.5em;
    bottom: 0;
    padding: 0 2%;
    z-index: 94;
    width: 100%;
    position: absolute;
    vertical-align: middle;
    box-sizing: border-box;
}
