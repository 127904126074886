.pageBackground {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    min-width: 50%;
    min-height: 50%;
    -webkit-user-select: none;
    /*background-image: url(../../../public/Images/people.jpg);*/
    background-repeat: no-repeat;
    background-size: cover;
    user-select: none;
    -webkit-user-select: none;
}

.signupArea {
    position: absolute;
    width: 302px;
    color: #e6f4ff;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.signupArea button,
.signupArea a {
    display: block;
    width: 100%;
    box-sizing: border-box;
    margin-top: 0.75rem;
}

.signupArea input {
    width: 100%;
    padding: 0.75rem;
    background: #ecf1fa;
    font-size: 1.2em;
    border-radius: 0;
}
.signupArea input:focus {
    background: #fff;
}

.errorMessage {
    background: #fb00002b;
    text-shadow: 1px 1px 8px #000;
    padding: 0.5rem;
    margin: 1rem 0;
}
