.chartList {
    list-style-type: none;
    width: 100%;
    position: relative;
}

.chartList li {
    display: flex;
    justify-content: flex-start;
    position: relative;
    vertical-align: top;
    padding: 1rem 0;
    border-bottom: 1px solid #ddd;
    border-left: 1px solid #eee;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.chartList li:first-child {
    border-left-width: 1px;
    border-left-style: solid;
    -webkit-border-image: -webkit-gradient(linear, 0 100%, 0 0, from(#eee), to(#fff)) 0 0 1 1;
    -webkit-border-image: -webkit-linear-gradient(bottom, #eee, #fff) 0 0 1 1;
    -moz-border-image: -moz-linear-gradient(bottom, #eee, #fff) 0 0 1 1;
    -o-border-image: -o-linear-gradient(bottom, #eee, #fff) 0 0 1 1;
    border-image: linear-gradient(to top, #eee, #fff) 0 0 1 1;
}

.chartList li:nth-child(2n) {
    background: #f9f9f9;
}

.chartPosition {
    margin: 37px 0px 0 0;
    font-size: 2.5em;
    font-weight: 300;
    text-align: center;
    color: #949494;
    width: 5rem;
    min-width: 5rem;
}

.chartImage {
    width: 120px;
    height: 120px;
}

.chartItemInfo {
    padding: 0.5rem 1.2rem;
    font-size: 1.4rem;
    text-wrap: wrap;
}

.playButtons {
    position: absolute;
    right: 1rem;
    top: 50%;
    font-size: 1.8rem;
    color: rgba(102, 178, 230, 0.3);
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    -ms-transform: translate(0%, -50%);
}

.chartList li:hover .playButtons {
    color: #66b2e6;
}
