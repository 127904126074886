.albumInfoContainer {
    font-size: 1.15em;
    text-align: left;
    width: 100%;
    display: flex;
    column-gap: 2.4rem;
}

.albumInfoContainer > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 0.5rem;
    margin-inline-end: 5rem;
}

.albumMenuBtn {
    position: absolute;
    top: 15px;
    right: 20px;
}

.albumTitle {
    font-size: 2em;
    font-weight: 500;
}

.albumArtists {
    font-size: 2em;
    font-weight: 500;
}

.albumPlayButton {
    border: 0;
    border-radius: 500px;
    padding: 1rem;
    margin: 1rem 0;
    background: #1db954;
    color: #fff;
    letter-spacing: 2px;
    cursor: pointer;
}

.musicStatistics {
    width: max-content;
    margin: 0 0 7px 8px;
}

.cdNumber {
    background: #f3f5fb;
    padding: 6px;
    margin-bottom: 10px;
    font-weight: 500;
}

.trackList {
    width: 100%;
    border: 0;
    border-spacing: 0;
    vertical-align: middle;
}

.trackList tr:hover {
    background-color: #e6f4ff;
}

.trackList tr td {
    height: 2.7em;
    border-bottom: 1px solid #e0e0e0;
    font-size: 1.05em;
}

.trackList tr td:nth-child(1) {
    width: 3%;
    white-space: nowrap;
    color: #8e9698;
}

.trackList tr td:nth-child(2) {
    width: 46%;
}

.trackList tr td:nth-child(3) {
    width: 40%;
    padding-left: 4%;
    padding-right: 4%;
    white-space: nowrap;
}

.trackList tr td:nth-child(4) {
    width: 2%;
    white-space: nowrap;
    color: #8e9698;
    text-align: right;
}

.trackList tr:last-child td {
    border-bottom: none;
}

.totalTime {
    margin-top: 5px;
    padding-top: 3px;
    text-align: right;
}

.totalTime > span {
    border-top: 2px solid #ccc;
    padding-top: 3px;
    font-weight: 500;
    font-size: 1.05em;
}

@media screen and (min-width: 768px) {
    .albumTitle {
        margin-right: 50px;
    }

    .albumInfoContainer {
        margin-right: 50px;
    }
}

@media screen and (max-width: 768px) {
    .albumInfoContainer {
        text-align: center;
        flex-direction: column;
        justify-content: center;
    }

    .albumInfoContainer > div {
        margin-inline-end: unset !important;
        margin: auto !important;
        height: auto !important;
    }

    .albumInfoContainer > div:last-child {
        padding-top: 1rem;
    }

    .musicStatistics {
        margin: auto;
    }

    .trackList tr td:nth-child(2) {
        width: 86%;
    }

    .trackList tr td:nth-child(3) {
        display: none;
        width: 0%;
    }
}
