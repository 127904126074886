.videoInput {
    width: 100%;
    padding: 0.4rem;
}

.warning {
    margin-top: 0.75rem;
    padding: 0.5rem;
    line-height: 1.5rem;
    background-color: #f0d3b9;
    border-radius: 4px;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
}

.footer * {
    vertical-align: middle;
}
