.selectionList {
    list-style: none;
    border: 1px solid #eee;
    max-height: 140px;
    overflow: auto;
}

.selectionList li {
    position: relative;
    list-style: none;
    color: #646464;
}

.richSelectionItem {
    padding: 0.5rem 0.65rem;
    margin: 1px 0;
}
.richSelectionItemSelected {
    background-color: rgb(92, 200, 130);
    background-color: #1db954;
    color: #fff !important;
}
.richSelectionItemDeselected {
    background-color: rgb(232, 91, 91);
    background-color: #b91d1d;
    color: #fff !important;
}

.simpleSelectionItem button {
    background: none;
    border: 0;
    padding: 0.5rem 0.65rem;
    width: 100%;
    text-align: start;
}

.simpleSelectionItem button:hover,
.selectedSimpleSelectionItem button {
    background: #eee;
}

.optionButtons {
    position: absolute;
    right: 0.35rem;
    top: 0.2rem;
}

.optionButtons button {
    background: none;
    color: inherit;
    user-select: none;
    border: 0;
    border-radius: 50%;
    padding: 0.5rem;
    line-height: 100%;
    width: 2rem;
    height: 2rem;
}

.optionButtons button:hover {
    background: #0000009c !important;
    color: #fff !important;
}

.seperator {
    border-top: 1px solid #eee;
    margin: 0.5rem 0 !important;
}
