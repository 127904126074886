.loginBackground {
    position: relative;
    width: 100%;
    height: 70%;
    min-height: 550px;
    /*background-image: url(../../../public/Images/turntable.jpg);*/
    background-repeat: no-repeat;
    background-size: cover;
    user-select: none;
    -webkit-user-select: none;
}

.loginBackgroundOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
}

.loginWrapper {
    color: #fff;
    background: rgba(0, 0, 0, 0.25);
    margin: 3rem auto 0;
    text-align: center;
    width: 21.1rem;
    padding: 1rem;
    border-radius: 5px;
}

.loginByTitle {
    text-align: center;
    padding-top: 5px;
    text-shadow: 0 0 1em #000;
    text-transform: uppercase;
    margin: 12px 0;
    /*border-top: 1px solid #bbb;*/
    background: linear-gradient(to right, #bbbbbb60 0%, #bbb 50%, #bbbbbb60 100%);
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100% 1px;
}

.externalLogin {
    display: flex;
    justify-content: center;
    column-gap: 1rem;
}

.bellinoLogin button,
.bellinoLogin a {
    display: block;
    width: 100%;
    box-sizing: border-box;
    margin-top: 0.75rem;
}

.bellinoLogin input {
    width: 100%;
    padding: 0.75rem;
    background: #ecf1fa;
    font-size: 1.2em;
    border-radius: 0;
}
.bellinoLogin input:focus {
    background: #fff;
}

.errorMessage {
    background: #fb00002b;
    text-shadow: 1px 1px 8px #000;
    padding: 0.5rem;
    margin: 1rem 0;
}

.featureBoxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    margin: 2.5rem auto;
    max-width: 1200px;
}

.featureBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    vertical-align: top;
    text-align: center;
    min-width: 250px;
    max-width: 300px;
    padding: 1.5rem 1.2rem;
    margin: 25px;
    font-size: 130%;
    box-shadow: 0px 0px 30px #eee;
    border-radius: 5px;
    background: #fff;
    color: #555;
}
