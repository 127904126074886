/* Classic Buttton */

.classicViewMode {
    /*font-family: 'lucida grande', tahoma, verdana, arial, sans-serif;*/
    background: #ebedf3;
    padding: 4px 8px;
    color: #333;
    font-weight: bold;
    vertical-align: middle;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.classicViewMode:hover {
    background: #e5e8ef;
}

/* Link Buttton */

.linkViewMode,
.decorationLinkViewMode {
    background: none;
    border: none;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    opacity: 0.95;
}

.linkViewMode:hover,
.decorationLinkViewMode:hover {
    opacity: 1;
}

.decorationLinkViewMode:hover {
    text-decoration: underline;
}

/* Fancy Buttton */

.fancyViewMode {
    border: 0;
    padding: 0.625rem 1.5625rem 0.6875rem;
    padding: 0.625rem 1.3rem;
    border-radius: 4px;
    color: #fff;
    font-weight: 700;
    vertical-align: middle;
    font-size: 1.1rem;
}

.fancyViewMode:disabled {
    cursor: default;
}

.circleFancyViewMode {
    border-radius: 50%;
}

.whiteFancyViewMode {
    background: #fff;
}
.whiteFancyViewMode:hover {
    background: #eee;
}
.whiteFancyViewMode:disabled {
    background-color: #999;
}

.blueFancyViewMode {
    background: #4593ad;
    background: #1ba1c7;
}
.blueFancyViewMode:hover {
    background: #49aaca;
}
.blueFancyViewMode:disabled {
    background-color: #9dc6d4;
}

.greenFancyViewMode {
    background: #45ad9b;
}
.greenFancyViewMode:hover {
    background: #5eaea0;
}
.greenFancyViewMode:disabled {
    background-color: #9dc6d4;
}

.grayFancyViewMode {
    background: rgb(228, 230, 235);
    color: #444;
}
.grayFancyViewMode:hover {
    background: rgb(219 221 226);
}
.grayFancyViewMode:disabled {
    background: rgb(228, 230, 235, 0.5);
    color: #777;
}
