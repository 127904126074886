.notificationsArea {
    position: relative;
    display: inline-block;
}

.notificationsBtn {
    color: inherit;
    background: #555;
    border-radius: 50%;
    border: 0;
    width: 2.5rem;
    height: 2.5rem;
    margin: 0 0.5rem;
}
.notificationsBtn:hover {
    background: #888;
}

.notificationsOn {
    color: red;
}

.notificationsCount {
    position: absolute;
    background: #ffff;
    color: #444;
    text-align: center;
    top: -0.25rem;
    left: 2.25rem;
    font-size: 0.9rem;
    font-weight: 500;
    width: 1.2rem;
    height: 1.2rem;
    line-height: 1.2rem;
    border-radius: 50%;
}

.notificationsBox {
    position: absolute;
    padding: 0.5rem 0;
    right: -3rem;
    font-size: 1.05rem;
    background-color: #fff;
    color: #000;
    overflow-x: hidden;
    overflow-y: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: min(100vw, 25rem);
    max-width: min(100vw - 1rem, 30rem);
    max-height: calc(100vh - 4rem);
    border-radius: 4px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    box-shadow:
        0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    z-index: 95;
    transition-duration: 0.25s;
    transition-property: all;
    -webkit-transition-duration: 0.25s;
    -webkit-transition-property: all;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.notificationsHeaderTitle {
    text-align: start;
    padding: 0 1rem 0.5rem 1rem;
}
