.searchContainer {
    width: 250px;
    position: relative;
    background: #f7f9fc;
    line-height: normal;
    overflow: hidden;
    box-sizing: border-box;
    cursor: text;
    color: #666;
    transition-property: width;
    transition-duration: 0.25s;
}

.searchContainer i {
    position: absolute;
    left: 0.5rem;
    top: calc(50% - 0.475rem);
}

.searchContainer input {
    background: inherit;
    line-height: inherit;
    overflow: hidden;
    height: inherit;
    border: 0 !important;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    padding-inline-start: 2rem;
    padding-inline-end: 0.75rem;
}

.searchContainer:not(.noborderViewMode):focus-within {
    border: 1px solid #4d90fe;
    background: #fff;
}

.searchContainer.noborderViewMode:focus-within {
    background: inherit;
}

/* Classic view */

.classicViewMode {
    border: 1px solid #c7ccd3;
}

/* Rounded view */

.roundedViewMode {
    border: 1px solid #c7ccd3;
    border-radius: 1rem;
}

/* No border view */

.noborderViewMode {
    border: 0;
}

@media screen and (max-width: 520px) {
    .searchContainer {
        width: 170px;
    }
}
