.pagePreloader {
    margin: 0 auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    text-align: center;
}

.preloaderInline {
    width: 100%;
    min-height: 150px;
    text-align: center;
    align-content: center;
}

.preloaderInlineBox {
    width: 100%;
    min-height: 150px;
    align-content: center;
    text-align: center;
    border: 1px solid #dbe2e5;
    box-sizing: border-box;
}

.pagePreloader > div,
.preloaderInline > div,
.preloaderInlineBox > div {
    margin: auto;
    text-align: center;
}

/* Bars Animation */

.animationBars > div {
    background-color: #21242e;
    height: 3em;
    width: 1em;
    margin-right: 2px;
    display: inline-block;
    -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
    animation: stretchdelay 1.2s infinite ease-in-out;
}

.animationBars > div:nth-child(2) {
    animation-delay: -1.1s;
}

.animationBars > div:nth-child(3) {
    animation-delay: -1s;
}

.animationBars > div:nth-child(4) {
    animation-delay: -0.9s;
}

.animationBars > div:nth-child(5) {
    animation-delay: -0.8s;
}

@keyframes stretchdelay {
    0%,
    100%,
    40% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }

    20% {
        transform: scaleY(1);
        -webkit-transform: scaleY(1);
    }
}

/* Hypnotic Animation */

.animationHypnotic {
    width: 30px;
    aspect-ratio: 0.577;
    color: #000;
    display: grid;
    background:
        linear-gradient(currentColor 0 0) top / 100% 1px,
        linear-gradient(currentColor 0 0) bottom/100% 1px,
        linear-gradient(to bottom right, #0000 calc(50% - 2px), currentColor calc(50% - 1px), #0000 50%) top/100% calc(100% + 2px),
        linear-gradient(to bottom left, #0000 calc(50% - 2px), currentColor calc(50% - 1px), #0000 50%) top/100% calc(100% + 2px);
    background-repeat: no-repeat;
    animation: l17 4s infinite linear;
}
.animationHypnotic::before,
.animationHypnotic::after {
    content: '';
    grid-area: 1/1;
    background: inherit;
    border: inherit;
    animation: inherit;
}
.animationHypnotic::after {
    animation-duration: 2s;
}
@keyframes l17 {
    100% {
        transform: rotate(1turn);
    }
}

/* Spinner Animation */

.animationSpinner {
    width: 50px;
    aspect-ratio: 1;
    display: grid;
    border-radius: 50%;
    background:
        linear-gradient(0deg, rgb(0 0 0/50%) 30%, #0000 0 70%, rgb(0 0 0/100%) 0) 50%/8% 100%,
        linear-gradient(90deg, rgb(0 0 0/25%) 30%, #0000 0 70%, rgb(0 0 0/75%) 0) 50%/100% 8%;
    background-repeat: no-repeat;
    animation: l23 1s infinite steps(12);
}
.animationSpinner::before,
.animationSpinner::after {
    content: '';
    grid-area: 1/1;
    border-radius: 50%;
    background: inherit;
    opacity: 0.915;
    transform: rotate(30deg);
}
.animationSpinner::after {
    opacity: 0.83;
    transform: rotate(60deg);
}
@keyframes l23 {
    100% {
        transform: rotate(1turn);
    }
}

/* Multi Spinner Animation */

.animationMultiSpinner {
    width: 50px;
    aspect-ratio: 1;
    display: grid;
    border: 4px solid #0000;
    border-radius: 50%;
    border-color: #ccc #0000;
    animation: l16 1s infinite linear;
}
.animationMultiSpinner::before,
.animationMultiSpinner::after {
    content: '';
    grid-area: 1/1;
    margin: 2px;
    border: inherit;
    border-radius: 50%;
}
.animationMultiSpinner::before {
    border-color: #888 #0000;
    animation: inherit;
    animation-duration: 0.5s;
    animation-direction: reverse;
}
.animationMultiSpinner::after {
    margin: 8px;
}
@keyframes l16 {
    100% {
        transform: rotate(1turn);
    }
}

/* Cross Spinner Animation */

.animationCrossSpinner {
    transform: rotateZ(45deg);
    perspective: 1000px;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    color: #777;
}
.animationCrossSpinner:before,
.animationCrossSpinner:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: 50%;
    transform: rotateX(70deg);
    animation: 1s spin linear infinite;
}
.animationCrossSpinner:after {
    color: #49aaca;
    transform: rotateY(70deg);
    animation-delay: 0.4s;
}

@keyframes rotate {
    0% {
        transform: translate(-50%, -50%) rotateZ(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotateZ(360deg);
    }
}

@keyframes rotateccw {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(-360deg);
    }
}

@keyframes spin {
    0%,
    100% {
        box-shadow: 0.2em 0px 0 0px currentcolor;
    }
    12% {
        box-shadow: 0.2em 0.2em 0 0 currentcolor;
    }
    25% {
        box-shadow: 0 0.2em 0 0px currentcolor;
    }
    37% {
        box-shadow: -0.2em 0.2em 0 0 currentcolor;
    }
    50% {
        box-shadow: -0.2em 0 0 0 currentcolor;
    }
    62% {
        box-shadow: -0.2em -0.2em 0 0 currentcolor;
    }
    75% {
        box-shadow: 0px -0.2em 0 0 currentcolor;
    }
    87% {
        box-shadow: 0.2em -0.2em 0 0 currentcolor;
    }
}

/* Circle Animation */

.animationCircle {
    width: 48px;
    height: 48px;
    display: inline-block;
    position: relative;
}
.animationCircle::after,
.animationCircle::before {
    content: '';
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #444;
    position: absolute;
    left: 0;
    top: 0;
    animation: circleanimloader 2s linear infinite;
}
.animationCircle::after {
    animation-delay: 1s;
}

@keyframes circleanimloader {
    0% {
        transform: scale(0);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}

/* Line Animation */

.animationLine {
    width: 0;
    height: 4.8px;
    display: inline-block;
    position: relative;
    background: #fff;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    animation: animFw 8s linear infinite;
}
.animationLine::after,
.animationLine::before {
    content: '';
    width: 10px;
    height: 1px;
    background: #fff;
    position: absolute;
    top: 9px;
    right: -2px;
    opacity: 0;
    transform: rotate(-45deg) translateX(0px);
    box-sizing: border-box;
    animation: coli1 0.3s linear infinite;
}
.animationLine::before {
    top: -4px;
    transform: rotate(45deg);
    animation: coli2 0.3s linear infinite;
}

@keyframes animFw {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

@keyframes coli1 {
    0% {
        transform: rotate(-45deg) translateX(0px);
        opacity: 0.7;
    }
    100% {
        transform: rotate(-45deg) translateX(-45px);
        opacity: 0;
    }
}

@keyframes coli2 {
    0% {
        transform: rotate(45deg) translateX(0px);
        opacity: 1;
    }
    100% {
        transform: rotate(45deg) translateX(-45px);
        opacity: 0.7;
    }
}

/* Search Animation */

.animationSearch {
    width: 48px;
    height: 48px;
    display: block;
    margin: 20px auto;
    position: relative;
    border: 3px solid #444;
    border-radius: 50%;
    box-sizing: border-box;
    animation: searchanimloader 2s linear infinite;
}
.animationSearch::after {
    content: '';
    box-sizing: border-box;
    width: 6px;
    height: 24px;
    background: #444;
    transform: rotate(-45deg);
    position: absolute;
    bottom: -20px;
    left: 46px;
}

@keyframes searchanimloader {
    0% {
        transform: translate(-10px, -10px);
    }
    25% {
        transform: translate(-10px, 10px);
    }
    50% {
        transform: translate(10px, 10px);
    }
    75% {
        transform: translate(10px, -10px);
    }
    100% {
        transform: translate(-10px, -10px);
    }
}
