.settings {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-gap: 0.5rem 1rem;
}

.settings input,
.settings select {
    padding: 0.65rem;
    margin-inline-end: 5px;
    max-width: 250px;
}

.grid {
    display: flex;
    grid-gap: 1rem;
}
