.AppContainer {
    min-height: calc(100vh - 4.5em);
    padding-top: 3em;
    padding-bottom: 1.5em;
}

.coverBackgroundImage {
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    user-select: none;
    -webkit-user-select: none;
    padding: 0;
}

.mainSearch {
    font-size: 1rem;
    margin-inline-start: 1rem;
}

.signinLink {
    display: inline-flex;
    margin-inline-end: 1rem;
    white-space: nowrap;
}

@media screen and (max-width: 520px) {
    .mainSearch {
        margin-inline-start: 0.5rem;
    }

    .signinLink {
        margin-inline-end: 0.5rem;
    }
}
