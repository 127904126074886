.score {
    margin: auto;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    font-size: 1.3rem;
}

.scoreSmall {
    width: 45px;
    height: 45px;
    line-height: 45px;
}
.scoreMedium {
    font-size: 1.35rem;
    width: 60px;
    height: 60px;
    line-height: 60px;
}
.scoreLarge {
    font-size: 1.4rem;
    width: 75px;
    height: 75px;
    line-height: 75px;
}

.scoreRank5 {
    background: #499b02;
}
.scoreRank4 {
    background: #5aad13;
}
.scoreRank3 {
    background: #ea8f3c;
}
.scoreRank2 {
    background: #ea3c3c;
}
.scoreRank1 {
    background: #ad1313;
}
