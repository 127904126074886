.logo {
    text-decoration: none;
    font-family: 'Brush Script MT', cursive;
    text-shadow: 1px 1px #000;
    color: #fff;
}
.logo:hover {
    text-decoration: none;
}

.largeLogo {
    font-size: 7rem;
}

.mediumLogo {
    font-size: 4rem;
}

.smallLogo {
    font-size: 2rem;
}
