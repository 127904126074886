.releaseDetails {
  text-align: start;
  font-size: 1.1rem;
}

.releaseTags {
  margin: 0.3rem 0 0.75rem 0;
}

.releaseTags a {
  margin-inline-end: 0.35rem;
}
