.section {
    width: 96%;
    max-width: 96%;
    margin-top: 1.2rem;
    padding: 1.25rem 2%;
    /*overflow: hidden;*/
    overflow-wrap: anywhere;
    position: relative;
}

.section:last-of-type {
    margin-bottom: 1.2rem;
}

.section:first-of-type:last-of-type {
    padding: 0.5rem 2%;
}

.noPaddingSection {
    width: 100%;
    max-width: 100%;
    padding: 0 !important;
}

.sectionBlurdedBackground {
    width: 100%;
    margin-top: 1.2rem;
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: 2px 2px 10px rgba(60, 60, 60, 0.3);
}

.sectionBlurdedBackground::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    filter: blur(10px);
    background: inherit;
}

.sectionBlurdedBackgroundInner {
    position: relative;
    width: 96%;
    padding: 1.25rem 2%;
    background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0.8));
}
