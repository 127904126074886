.buttonView {
    border: 0;
    padding: 0.625rem 1.5625rem 0.6875rem;
    padding: 0.625rem 1.3rem;
    border-radius: 4px;
    color: #fff;
    font-family: Arial;
    font-weight: 700;
    vertical-align: middle;
    font-size: 1.1rem;
    line-height: normal;
    text-decoration: none;
}
.buttonView:hover {
    text-decoration: none;
}

.blueButtonView {
    background: #4593ad;
    background: #1ba1c7;
}
.blueButtonView:hover {
    background: #49aaca;
}

.greenButtonView {
    background: #45ad9b;
}
.greenButtonView:hover {
    background: #5eaea0;
}

.SeeMoreView:hover {
    text-decoration: none;
}
.SeeMoreView:hover:after {
    content: '\00a0\00BB\00a0See More';
    display: inline-block;
    color: #bababa;
}
