/************************************************************************************/
/* Classic Tabs */

.tabsContainer {
    padding: 0 2%;
    color: #fff;
    background-color: #6c8ea8;
    width: 96%;
    overflow: hidden;
    white-space: nowrap;
}

.tab {
    font-family: inherit;
    font-size: 1.2rem;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    background: none;
    border: 0;
    color: inherit;
}

.tab a,
.tab button {
    font-family: inherit;
    padding: 10px 20px;
    text-decoration: none;
    display: inline-block;
    background: none;
    border: 0;
    color: inherit;
}

.tab:hover {
    background: #789ab4;
}

.selectedTab,
.selectedTab:hover {
    color: #6c8ea8;
    background: #f8f8f8;
    border-top-left-radius: 0.4em;
    border-top-right-radius: 0.4em;
    border-top: 3px solid #6c8ea8;
}

.selectedTab a,
.selectedTab:hover a {
    padding: 0.85rem 1.3rem;
}

.tab a:hover,
.selectedTab a:hover {
    text-decoration: none;
}

/************************************************************************************/
/* Simple Tabs */

.simpleTabsContainer {
    width: 96%;
    padding: 0 2% 0 2%;
    color: #555;
    background-color: #fff;
    overflow: hidden;
    white-space: nowrap;
    font-size: 1.2em;
    border-bottom: 1px solid rgb(200, 200, 200);
}

.simpleTab {
    display: inline-block;
    height: 100%;
}

.simpleTab a {
    text-decoration: none;
    display: inline-block;
    text-align: center;
    padding: 0.7rem 1.3rem;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.simpleTab:hover {
    border-bottom: 3px solid #cacaca;
}

.simpleTabSelected,
.simpleTabSelected:hover {
    border-bottom: 3px solid #6c8ea8;
}

.simpleTab a:hover,
.simpleTabSelected a:hover {
    text-decoration: none;
}

/************************************************************************************/
/* Simple2 Tabs */

.buttonsTabsContainer {
    color: #000;
    overflow: hidden;
    white-space: nowrap;
    font-size: 1.1em;
}

.buttontab {
    border: 1px solid #d0d0d0;
    border-left: 0;
    display: inline-block;
    min-width: 5.5rem;
    text-align: center;
    box-sizing: border-box;
    background-color: #f8f8f8;
}

.buttontab:first-child {
    border-left: 1px solid #d0d0d0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.buttontab:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.buttontab a,
.buttontab button {
    font-family: inherit;
    padding: 0.5rem 0.5rem;
    text-decoration: none;
    display: inline-block;
    background: none;
    border: 0;
    width: 100%;
    height: 100%;
    color: inherit;
    box-sizing: border-box;
}

.buttontab:hover {
    background-color: #e0e0e0;
}

.buttonSelectedTab,
.buttonSelectedTab:hover {
    background-color: #0099cc;
    border-color: #0099cc;
    color: #fff;
}

.buttontab a:hover,
.buttonSelectedTab a:hover {
    text-decoration: none;
}
