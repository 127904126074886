.chatsAndMessages {
    display: grid;
    grid-template-columns: minmax(10rem, 35rem) auto;
}

.chatsAndMessages > div:last-child {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding-inline-start: 1.5rem;
}

.chatsOrMessages {
    width: 100%;
}
