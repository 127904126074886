.filtersBtn {
    position: absolute;
    background: #fff;
    color: #000;
    border: 1px solid #bbb;
    border-radius: 5px;
    padding: 0.35rem 0.85rem;
    top: 3.9rem;
    right: 2%;
    display: flex;
    align-items: center;
    font-weight: 600;
    gap: 0.5rem;
}

.filtersBtn:hover {
    background: #f1f1f1;
}

.filtersBtn.active {
    background: #49aaca;
    border: 1px solid #49aaca;
    color: #fff;
}
