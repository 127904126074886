.metadataInput {
    width: 100%;
    padding: 0.4rem;
    display: block;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}

.footer * {
    vertical-align: middle;
}
