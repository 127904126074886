.musicPlayer {
    position: fixed;
    width: 100%;
    background-color: #3c3c3c;
    /*overflow: hidden;*/
    color: #fff;
    border-top: 1px solid #666;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    z-index: 95;
    bottom: 0;
    padding: 0 2%;
    display: flex;
    justify-content: space-between;
    transition-property: height, visibility;
    transition-duration: 0.15s;
}

.musicPlayer:not(.open) .youtubeContainer {
    visibility: hidden;
}
.musicPlayer.open .youtubeContainer {
    visibility: visible;
}

.musicPlayer > div:first-child {
    width: calc(100% - 320px - 1rem);
}
.musicPlayer > div:last-child {
    width: 320px;
    margin-left: 1rem;
}

.playerToolbar {
    display: flex;
    align-items: center;
    /*column-gap: 1rem;*/
    height: 35px;
    color: #ddd;
    white-space: nowrap;
}

.playerToolbar > div:first-child {
    font-weight: 700;
    color: #dab789;
    margin-right: 1.5rem;
}

.playerToolbar button {
    background: none;
    border: 0;
    color: inherit;
    width: 3rem;
    padding: 0 0.5rem;
    text-align: center;
    /* border-radius: 50%; */
    height: 100%;
}

.playerToolbar button:hover {
    background: #5c5c5c;
}

.playerTime {
    min-width: 5.7rem;
    text-align: right;
}

.playerVolume {
    margin: 0 0.5rem;
    /* height: 100%; */
    display: inline-flex;
}

.playerTrackslist {
    height: calc(100% - 38px);
    overflow: auto;
}

.playerTrackslist > ul {
    list-style: none;
}

.playerTrackslist > ul > li {
    display: grid;
    grid-template-columns: auto 7.5rem 3.5rem;
    grid-gap: 10px;
    align-items: center;
    vertical-align: middle;
    padding: 8px 0;
}

.playerTrackslist > ul > li:not(:last-child) {
    border-bottom: 1px solid #959595;
}

.playerTrackslist > ul > li:hover {
    background: #333;
}
