.chatContainer {
    height: 100%;
}

.chatContainer ul {
    overflow: auto;
    visibility: hidden;
    height: calc(100% - 4.75rem);
}

.chatContainer li {
    list-style: none;
    position: relative;
    vertical-align: top;
    padding: 0.5rem 0;
    display: flex;
    justify-content: flex-start;
    column-gap: 1rem;
    word-wrap: break-word;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.outMessage {
    flex-direction: row-reverse;
    margin-inline-end: 1rem;
}

.outMessage .message {
    background: #eaffd8 !important;
}

.message {
    display: inline-block;
    background: #fff;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #eee;
    min-width: 40px;
    max-width: max(70%, 250px);
}

.messageContent {
    position: relative;
    margin-bottom: 20px;
    max-width: 500px;
    font-size: 1.1em;
}

.messageDate {
    position: absolute;
    bottom: 10px;
    color: rgb(180, 180, 180);
}

.chatTitle {
    text-align: center;
    padding: 0 !important;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 1.5rem;
}

.chatTitle:first-child {
    border-bottom: none;
}

.chatTitle > span {
    font-weight: 500;
    top: 0.5em;
    padding: 0 10px;
    background-color: #f8f8f8;
    color: #b1b1b1;
    position: relative;
    margin: 0 auto;
}

.messagesInput {
    margin-top: 0.75rem;
}

.messagesInput textarea {
    height: 4rem;
    /*background: inherit;*/
}

.messagesInput textarea:focus {
    background: #fff;
}
