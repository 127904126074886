.menuButton {
    position: relative;
    display: inline-flex;
    align-items: center;
}

.menuButtonFixed {
    display: inline-flex;
    align-items: center;
}

.smallMenuBtn,
.mediumMenuBtn,
.largeMenuBtn {
    background: none;
    border: 0;
    border-radius: 50%;
    vertical-align: middle;
    color: inherit;
}

.smallMenuBtn {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.3rem;
    line-height: 1.3rem;
}
.mediumMenuBtn {
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;
    line-height: 1.5rem;
}
.largeMenuBtn {
    width: 3.5rem;
    height: 3.5rem;
    font-size: 2rem;
    line-height: 2rem;
}

.whiteMenuBtn {
    background: rgba(255, 255, 255, 0.95);
    border: 0;
    border-radius: 50%;
    /*padding: 1% 1.668%;*/
    text-align: center;
    cursor: pointer;
    z-index: 2;
    box-shadow: 0 1px 3px rgba(100, 100, 100, 0.3);
}
.whiteMenuBtn:hover {
    box-shadow: 0 3px 8px rgba(100, 100, 100, 0.3);
}

.transparentMenuBtn {
    background: none;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    z-index: 2;
}
.transparentMenuBtn:hover {
    background: #eee;
}
