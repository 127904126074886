.imgContainer {
    position: relative;
    display: inline-block;
    box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.2);
    border: 8px solid #fff;
    width: fit-content;
    height: fit-content;
}

.imgEditBtnContainer {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.imgEditBtn {
    width: 3rem;
    height: 3rem;
    border: 0;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #ccc;
}
.imgEditBtn:hover {
    background-color: rgba(0, 0, 0, 0.65);
    color: #eaeaea;
}
