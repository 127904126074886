/* Inline mode */

.itemPlaybar {
    /*white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;*/
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
}

.itemPlaybar > div {
    display: inline-block;
}

.itemPlaybar > div:first-child {
    display: flex;
    min-width: 4rem;
    padding-inline-end: 0.5rem;
}

.playItem,
.plusItem {
    background: none;
    cursor: pointer;
    margin-right: 0.5rem;
    color: inherit;
    border: 0;
    font-size: 1.15em;
    filter: alpha(opacity = 85);
    opacity: 0.85;
    -webkit-opacity: 0.85;
    -moz-opacity: 0.85;
}

.playItem:hover,
.plusItem:hover {
    filter: alpha(opacity = 100);
    opacity: 1;
    -webkit-opacity: 1;
    -moz-opacity: 1;
}

/* Overlay mode */

.icon {
    width: 50px;
    height: 50px;
    top: 50%;
    left: 50%;
    margin-top: -25px;
    background-size: 48px 48px;
    border: 0;
    display: inline-block;
    position: absolute;
    cursor: pointer;
    filter: alpha(opacity = 65);
    opacity: 0.65;
    -webkit-opacity: 0.65;
    -moz-opacity: 0.65;
}
.icon:hover {
    filter: alpha(opacity = 100);
    opacity: 1;
    -webkit-opacity: 1;
    -moz-opacity: 1;
}

.playItemIcon {
    /*background: url(../../../public/Images/play-w-48.ico) center no-repeat;*/
    margin-left: -55px;
}

.plusItemIcon {
    /*background: url(../../../public/Images/plus-w-48.ico) center no-repeat;*/
    margin-left: 5px;
}
