.containerGrid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    /*grid-gap: 0 2rem;*/
}

.containerGrid > div:last-child {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding-inline-start: 1.5rem;
}

.container {
    width: 100%;
}
