.dialog {
    position: fixed;
    background: #fff;
    color: #000;
    overflow: visible;
    font-size: 1rem;
    border: 0;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.5);
    z-index: 100;
    min-height: 50px;
}

.dialogPC {
    top: 50%;
    left: 50%;
    width: 450px;
    min-width: 150px;
    max-width: 1520px;
    border-radius: 4px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

.dialogMobile {
    top: unset;
    bottom: 0;
    left: 0;
    width: 100%;
    min-width: 100%;
    border-radius: 0;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

dialog::backdrop {
    background: rgba(0, 0, 0, 0.45);
}

.dialogHeader {
    background: #f5f6f7;
    border-bottom: 1px solid #e5e5e5;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    padding: 0.8rem 1.3rem;
    font-size: 1.3em;
    line-height: 1.3rem;
    font-weight: 500;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.dialogHeader > div {
    display: inline-block;
}

.closeDialog {
    vertical-align: middle;
    cursor: pointer;
    text-align: center;
    background: none;
    border: 0;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
}
.closeDialog:after {
    content: '\2716';
}
.closeDialog:hover {
    background-color: #d6d6d6;
}

.dialogContent {
    padding: 0.8rem 1.3rem 1.5rem;
    overflow-y: auto;
    max-height: min(450px, calc(80vh - 3rem));
}
