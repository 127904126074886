.musicStatistics {
  min-width: 100%;
  width: 100%;
}

.musicStatistics > div {
  vertical-align: top;
  padding-right: 1rem;
  border-right: 1px solid #e9eaed;
  margin-right: 1rem;
  text-align: left;
  display: inline-block;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.musicStatistics > div:last-child {
  border: none;
  padding-right: 0;
  margin-right: 0;
}

.playsInfo {
  font-size: 2rem;
  font-weight: 700;
  color: #4d556d;
  cursor: inherit;
}

sub {
  color: #969696;
  font-size: 0.95em;
}
