.radioContainer {
    background: rgba(248, 248, 248, 0.96);
    text-align: center;
    min-height: 100vh;
    padding: 9rem 0;
    box-sizing: border-box;
}

.playRadioButton {
    background: #fff;
    color: #5fb5d0;
    border-radius: 50%;
    display: inline-block;
    box-shadow: 1px 1px 10px #7b7b7b;
    margin-bottom: 2rem;
    border: 0.65rem solid #fff;
    box-sizing: border-box;
    width: 12.2rem;
}

.playRadioButton:hover {
    color: #479cb7;
}

.playRadioButton i {
    font-size: 11rem;
}

.radioFilters {
    display: flex;
    flex-direction: column;
    width: 20rem;
    align-items: center;
    margin: 0 auto;
    font-size: 1.5em;
}

.radioFilters > button,
.radioFilters > select {
    width: 20rem;
    display: inline-block;
    padding: 0.35rem 0;
    margin: 0.5rem 0;
    cursor: pointer;
    background: none;
    border: 1px solid #777;
    color: #636363;
}

.radioFilters > button:hover,
.radioFilters > select:hover {
    background: rgba(255, 255, 255, 0.85);
}
