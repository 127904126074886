.genreWiki {
    width: calc(100% - 0.75rem);
    max-height: 250px;
    color: #444;
    font-size: 1.2rem;
    line-height: 2rem;
    cursor: auto;
    padding-right: 0.75rem;
    letter-spacing: 0.5px;
    display: inline-block;
    overflow: auto;
}

.genreWiki p:not(:last-child) {
    margin-bottom: 1rem;
}

.genreWiki > div {
    max-width: 800px;
}
