.ratingInput {
    display: inline-block;
    padding: 2px;
    width: 55px;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}
