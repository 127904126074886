.messagesHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.messagesHeader > div:first-child {
    display: flex;
    align-items: center;
    column-gap: 1rem;
}

.backToChatsBtn {
    background: none;
    border: 0;
}

.messagesList {
    min-height: calc(100vh - 13.65rem);
    height: calc(100vh - 13.65rem);
}
