.icon {
    width: 50px;
    height: 50px;
    top: 50%;
    left: 50%;
    margin-top: -25px;
    background-size: 48px 48px;
    border: 0;
    display: inline-block;
    position: absolute;
    cursor: pointer;
    filter: alpha(opacity = 65);
    opacity: 0.65;
    -webkit-opacity: 0.65;
    -moz-opacity: 0.65;
}
.icon:hover {
    filter: alpha(opacity = 100);
    opacity: 1;
    -webkit-opacity: 1;
    -moz-opacity: 1;
}

.play_item_icon {
    /*background: url(../../../public/Images/play-w-48.ico) center no-repeat;*/
    margin-left: -55px;
}

.plus_item_icon {
    /*background: url(../../../public/Images/plus-w-48.ico) center no-repeat;*/
    margin-left: 5px;
}

.videoToolbar {
    background: #333;
    color: #eee;
    padding: 10px;
    border-top: 1px solid #989898;
    border-radius: 0 0 5px 5px;
    display: flex;
    justify-content: space-between;
}
.videoToolbar button {
    background: none;
    border: 0;
    color: inherit;
    height: 100%;
}

.videoDuration {
    font-size: 1.1em;
    font-weight: 500;
    position: absolute;
    bottom: 3px;
    right: 3px;
    color: #ddd;
    background: rgba(0, 0, 0, 0.5);
    text-shadow: 1px 1px #000;
    text-align: right;
    padding: 3px;
    cursor: inherit;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}
