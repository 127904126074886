.imgContainer {
    display: inline-block;
}

.imgContainer > span {
    display: inline-block;
}

.imgContainer > * {
    transition-duration: 0.25s;
    transition-property: opacity;
}
