.artistInfoContainer {
    font-size: 1.15em;
    text-align: left;
    width: 100%;
    display: flex;
    column-gap: 2.4rem;
}

.artistInfoContainer > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 0.5rem;
    margin-inline-end: 5rem;
}

.artistMenuBtn {
    position: absolute;
    top: 15px;
    right: 20px;
}

.artistTitle {
    font-size: 2em;
    font-weight: 500;
}

.artistPlayButton {
    border: 0;
    border-radius: 500px;
    padding: 1rem;
    margin: 1rem 0;
    background: #1db954;
    color: #fff;
    letter-spacing: 1.5px;
    cursor: pointer;
    display: inline-flex;
    width: auto;
    font-size: 1.1rem;
    align-items: center;
}

.musicStatistics {
    width: max-content;
    margin: 0 0 7px 8px;
}

.similarArtistOverlay {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.45);
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.6)));
    background: -o-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.6)));
    background: -ms-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.6)));
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
    color: #eaeaea;
    padding: calc(100% - 3rem) 0.5rem 1rem 0.5rem;
    cursor: pointer;
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    line-height: 2rem;
    white-space: normal;
    box-sizing: border-box;
    text-decoration: none;
}
.similarArtistOverlay:hover {
    text-decoration: none;
}

@media screen and (min-width: 720px) {
    .albumTitle {
        margin-right: 50px;
    }

    .artistInfoContainer {
        margin-right: 50px;
    }
}

@media screen and (max-width: 720px) {
    .artistInfoContainer {
        text-align: center;
        flex-direction: column;
        justify-content: center;
    }

    .artistInfoContainer > div {
        margin-inline-end: unset !important;
        margin: auto !important;
        height: auto !important;
    }

    .artistInfoContainer > div:last-child {
        padding-top: 1rem;
    }

    .musicStatistics {
        margin: auto;
    }
}
