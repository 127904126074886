.title {
    font-weight: 500;
    font-size: 1.05rem;
    margin-bottom: 0.65rem;
}

.footer {
    display: flex;
    margin-top: 1rem;
}
