.variousArtistsInfoContainer {
    font-size: 1.15em;
    text-align: left;
    width: 100%;
    display: flex;
    column-gap: 2.4rem;
}

.variousArtistsTitle {
    font-size: 2em;
    font-weight: 500;
}

.variousArtistsWiki {
    font-size: 1.1em;
    color: #444;
    line-height: 1.75em;
    cursor: auto;
    max-width: 700px;
    margin-top: 1rem;
}

@media screen and (min-width: 720px) {
    .variousArtistsInfoContainer {
        margin-right: 50px;
    }
}

@media screen and (max-width: 720px) {
    .variousArtistsInfoContainer {
        text-align: center;
        flex-direction: column;
        justify-content: center;
    }

    .variousArtistsInfoContainer > div {
        margin-inline-end: unset !important;
        margin: auto !important;
        height: auto !important;
    }
}
