.filters {
    display: grid;
    align-items: center;
    grid-template-columns: auto auto auto;
    justify-content: start;
    gap: 1rem;
    margin: 0.5rem 0 1.5rem 0;
}

.filters select {
    background: #f7f9fc;
    border-radius: 1rem;
}

.filters select:focus {
    background: #fff;
}

.usersGrid {
    list-style-type: none;
    overflow: hidden;
    padding-bottom: 1px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 2rem;
    /*justify-content: space-between;*/
}
/*.usersGrid:after {
    content: '';
    flex: auto;
}*/
