.friendsGrid {
    list-style-type: none;
    overflow: hidden;
    padding-bottom: 1px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 2rem;
    /*justify-content: space-between;*/
}
.friendsGrid:after {
    content: '';
    flex: auto;
}
