.container {
    display: inline-block;
    vertical-align: top;
    height: fit-content;
}

.cardImage {
    position: relative;
}

.cardDetails {
    vertical-align: top;
    padding: 10px 5px;
    overflow: hidden;
}

.cardDetails * {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
