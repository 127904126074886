.toolbar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.photosGrid {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 1rem;
}
