.chartBar {
  background-color: #66b2e6;
  color: #fff;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  height: inherit;
  display: table;
  padding: 0 4px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.chartBar:hover {
  background-color: #195e8d;
}
