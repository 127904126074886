.playlistTracks {
    min-height: calc(100vh - 17rem);
    max-height: calc(100vh - 17rem);
    height: calc(100vh - 17rem);
    overflow: auto;
}

.playlistToolbar {
    display: flex;
    padding: 0.2rem 0.6rem;
    background: #e8e8e8;
    color: #555;
    border-bottom: 1px solid #ccc;
    margin-bottom: 0.5rem;
    font-size: 1.2em;
    min-height: 3rem;
    justify-content: space-between;
    align-items: center;
}

.playlistToolbar > div:first-child {
    display: flex;
    align-items: center;
    column-gap: 1rem;
}

.playlistToolbarSaveChnges {
    display: none;
}

.backToPlaylistsBtn {
    background: none;
    border: 0;
}

.trackToolbar {
    color: #555;
    display: flex;
    align-items: center;
}

.emptyPlaylist {
    text-align: center;
    color: #999;
    cursor: default;
    font-size: 1.1rem;
    margin-top: 0.5rem;
}
