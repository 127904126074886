.option {
    display: inline-block;
    background: #e0e0e0;
    color: #000;
    padding: 8px;
    text-decoration: none;
}
.option:hover {
    text-decoration: none;
    background: #ccc;
}

.selectedOption {
    background: #09c !important;
    color: #fff !important;
    cursor: default;
}

.optionL {
    border-radius: 4px 0 0 4px;
}
.optionR {
    border-radius: 0 4px 4px 0;
}
