.header {
    background-color: rgba(40, 40, 40, 0.9);
    color: #f3f5fb;
    height: 2.35rem;
    line-height: 2.35rem;
    font-size: 1.35rem;
    width: 96%;
    position: fixed;
    vertical-align: middle;
    top: 0;
    z-index: 96;
    padding: 5px 2%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    box-shadow: 2px 2px 10px rgba(60, 60, 60, 0.3);
}

.section {
    display: flex;
    align-items: center;
    column-gap: 0.15rem;
}

.section a {
    text-decoration: none !important;
}

.section > button:hover,
.section > a:hover {
    color: #fff;
}
