.sliderContainer {
    overflow: hidden;
    position: relative;
}

.list {
    list-style-type: none;
    width: 100%;
    display: block;
    white-space: nowrap;
    overflow: visible;
    transition-property: transform;
    transition-duration: 0.5s;
}
.list.touch {
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch; /* Smooth scrolling for iOS devices */
}

.listItem {
    display: inline-block;
    vertical-align: top;
    text-align: center;
}
.listItem:not(:last-child) {
    margin-inline-end: 1.5em;
}
.list::-webkit-scrollbar {
    display: none;
}

.sliderLeftBtn,
.sliderRightBtn {
    border: 0;
    position: absolute;
    z-index: 11;
    top: 0.85rem;
    padding: 1rem 0.5rem;
    cursor: pointer;
    background: #fff;
    color: #444;
    box-shadow: rgba(100, 100, 100, 0.57) 0 1px 3px;
    transition-property: opacity;
    transition-duration: 0.5s;
}
.sliderLeftBtn:disabled,
.sliderRightBtn:disabled {
    opacity: 0;
    z-index: -1;
}

.sliderLeftBtn {
    left: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.sliderRightBtn {
    right: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
