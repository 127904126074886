.container {
    border: 1px solid #bbb;
    border-radius: 5px;
    overflow: hidden;
}

.container input {
    border: none !important;
    outline: 0 !important;
    background: none !important;
}

.container:focus-within {
    border: 1px solid #4d90fe;
    background: #fff;
}

.searchingMode {
    cursor: default;
}

.searchResults {
    list-style: none;
    max-height: 12rem;
    overflow-y: auto;
    border-top: 1px solid #bbb;
}

.searchResults li {
    position: relative;
    padding: 0.7rem 1.5rem 0.7rem 0.7rem;
}

.searchResults .searchResult {
    cursor: pointer;
}

.searchResults .checkbox {
    position: absolute;
    color: #49aaca;
    font-size: 1.35rem;
    right: 0.5rem;
    top: calc(50% - 0.475rem);
    top: 50%;
}

.searchResults .searchResult:hover {
    background-color: #f5f6f7;
}

.searchResults .searchResult:not(:last-child) {
    border-bottom: 1px solid #eee;
}

.selectedItems {
    margin: 0.5rem;
    display: flex;
    flex-flow: wrap;
    row-gap: 0.25rem;
    column-gap: 0.25rem;
    max-height: 10rem;
    overflow-y: auto;
}

.selectedItem {
    border: 1px solid #eee;
    padding: 0.35rem;
    display: inline-block;
    border-radius: 5px;
    font-weight: 500;
    max-width: 90%;
    color: #fff;
    background: #7ccaff;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: default;
}

.selectedItem button {
    color: #fff;
    background: none;
    border: none;
    border-radius: 50%;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0.5rem;
}

.selectedItem:hover button {
    background: #ffffff61;
}
