.trackInfoContainer {
    font-size: 1.15em;
    text-align: left;
    width: 100%;
    display: flex;
    column-gap: 2.4rem;
}

.trackInfoContainer > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 0.5rem;
    margin-inline-end: 5rem;
}

.trackMenuBtn {
    position: absolute;
    top: 15px;
    right: 20px;
}

.albumTitle {
    font-size: 2em;
    font-weight: 500;
}

.albumArtists {
    font-size: 2em;
    font-weight: 500;
}

.albumPlayButton {
    border: 0;
    border-radius: 500px;
    padding: 1rem;
    margin: 1rem 0;
    background: #1db954;
    color: #fff;
    letter-spacing: 2px;
    cursor: pointer;
}

.musicStatistics {
    width: max-content;
    margin: 0 0 5px 8px;
}

.trackVideosContainer,
.trackLyricsContainer {
    display: inline-block;
    margin-top: 1rem;
    padding: 1% 2% 1% 2%;
    background-color: #f8f8f8;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.trackVideosContainer {
    width: 58%;
    margin-right: 1%;
    vertical-align: top;
}

.trackLyricsContainer {
    width: 41%;
    vertical-align: top;
}

.addBtn {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 250px;
    color: #fff;
    cursor: pointer;
    height: 100%;
    width: 100%;
    border: 0;
}

.addBtn:hover {
    color: #585858;
    user-select: none;
    -webkit-user-select: none;
}

.videos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    justify-content: start;
    grid-gap: 1rem;
    min-height: 258px;
    max-height: 490px;
    overflow: auto;
}

.lyrics {
    text-align: center;
    max-height: 490px;
    overflow: auto;
    font-size: 1.35em;
    color: #444;
    line-height: 1.5em;
    cursor: auto;
    padding-right: 10px;
}

.lyricsEditor {
    max-height: 490px;
    font-size: 1.35em;
    line-height: 1.5em;
    min-height: 19.5rem;
}

.noLyrics {
    color: rgb(88, 88, 88);
    font-size: 25rem;
    line-height: 28rem;
    text-align: center;
}

.toolbar {
    border-top: 2px solid #c7ccd3;
    margin-top: 5px;
    padding-top: 5px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
}

.similarTrackOverlay {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.45);
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.6)));
    background: -o-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.6)));
    background: -ms-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.6)));
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
    color: #eaeaea;
    padding: calc(100% - 3rem) 0.5rem 1rem 0.5rem;
    cursor: pointer;
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    line-height: 2rem;
    white-space: normal;
    box-sizing: border-box;
    text-decoration: none;
}

.similarTrackLink {
    position: absolute;
    bottom: 0;
    left: 0;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
    color: #eaeaea;
    padding: 0 0.5rem 1rem 0.5rem;
    cursor: pointer;
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    line-height: 2rem;
    white-space: normal;
    box-sizing: border-box;
    text-decoration: none;
}
.similarTrackLink:hover {
    text-decoration: none;
}

@media screen and (min-width: 720px) {
    .albumTitle {
        margin-right: 50px;
    }

    .trackInfoContainer {
        margin-right: 50px;
    }
}

@media screen and (max-width: 720px) {
    .trackInfoContainer {
        text-align: center;
        flex-direction: column;
        justify-content: center;
    }

    .trackInfoContainer > div {
        margin-inline-end: unset !important;
        margin: auto !important;
        height: auto !important;
    }

    .trackInfoContainer > div:last-child {
        padding-top: 1rem;
    }

    .musicStatistics {
        margin: auto;
    }

    .trackVideosContainer {
        width: 100%;
        margin-right: 0;
    }

    .trackLyricsContainer {
        width: 100%;
    }

    .videos {
        justify-content: center;
    }
}
