.musicPlayer {
    position: fixed;
    width: 100%;
    background-color: #3c3c3c;
    /*overflow: hidden;*/
    color: #fff;
    border-top: 1px solid #666;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    z-index: 96;
    bottom: 0;
    padding: 0 2%;
    transition-property: height, visibility;
    transition-duration: 0.15s;
}
.musicPlayer.open {
    height: 100dvh;
    min-height: -webkit-fill-available;
    min-height: 100dvh;
    overflow-y: auto;
    top: 0;
}

.musicPlayer:not(.open) .youtubeContainer {
    visibility: hidden;
    height: 0;
}
.musicPlayer.open .youtubeContainer {
    visibility: visible;
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* This maintains a 16:9 aspect ratio (height = 9 / 16 = 56.25%) */
    height: 0;
    overflow: hidden;
}

.musicPlayer.open .youtubeContainer iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.musicPlayer > div {
    width: 100%;
}

.minimizeButton {
    border: 0;
    background: none;
    width: 100%;
    color: #fff;
    font-size: 1.25rem;
    padding: 0.75rem 0;
}

.timeControl {
    padding: 0.5rem 0;
}
.timeControl > div:last-child {
    display: flex;
    justify-content: space-between;
    padding-top: 0.35rem;
}

.playerToolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /*column-gap: 1rem;*/
    height: 35px;
    color: #ddd;
    white-space: nowrap;
    position: relative;
}

.playerToolbar > div {
    height: 100%;
}

.playerToolbar > div:first-child {
    font-weight: 700;
    color: #dab789;
    margin-right: 1.5rem;
    height: auto;
}

.playerToolbar > div:nth-child(2) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
}
.musicPlayer.open .playerToolbar > div:nth-child(2) {
    display: flex;
    background: #5c5c5c;
    column-gap: 0.5rem;
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    margin-top: -1.75rem;
}

.playerToolbar button {
    background: none;
    border: 0;
    color: inherit;
    width: 2.5rem;
    padding: 0 0.3rem;
    text-align: center;
    height: 100%;
}

.playerToolbar button:hover {
    background: #5c5c5c;
}

.playerTime {
    min-width: 5.7rem;
    text-align: right;
}

.playerVolume {
    margin: 0 0.5rem;
    /* height: 100%; */
    display: inline-flex;
}

.playerTrackslist {
    /*height: calc(100% - 300px);*/
    overflow: auto;
}

.playerTrackslist > ul {
    list-style: none;
}

.playerTrackslist > ul > li {
    display: grid;
    grid-template-columns: auto 7.5rem 3.5rem;
    grid-gap: 10px;
    align-items: center;
    vertical-align: middle;
    padding: 8px 0;
}

.playerTrackslist > ul > li > div:not(:first-child) {
    text-align: end;
}

.playerTrackslist > ul > li:not(:last-child) {
    border-bottom: 1px solid #959595;
}

.playerTrackslist > ul > li:hover {
    background: #333;
}
