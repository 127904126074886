.conversionsList {
    overflow: auto;
    min-height: calc(100vh - 13.65rem);
    height: calc(100vh - 13.65rem);
}

.lastMessagePreview {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 25rem;
}
