.menuBox {
    position: absolute;
    background-color: #fff;
    color: #000;
    overflow-y: auto;
    max-height: calc(100vh - 4rem);
    border-radius: 4px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    box-shadow:
        0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    z-index: 95;
    transition-duration: 0.25s;
    transition-property: all;
    -webkit-transition-duration: 0.25s;
    -webkit-transition-property: all;
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.menu {
    list-style-type: none;
    margin: 0.5rem 0;
    font-size: 1.05rem;
}

.menu li {
    width: 100%;
    /*overflow: hidden;*/
    line-height: normal;
}

.menuItem {
    display: block;
    color: #000;
    padding: 0.75rem 1.75rem;
    text-decoration: none !important;
    text-wrap: nowrap;
    background: none;
    text-align: start;
    box-sizing: border-box;
    border: 0;
    width: 100%;
    user-select: none;
    font-family: inherit;
}
button.menuItem:hover,
a.menuItem:hover {
    background-color: #f1f1f1;
}

.lastSectionItem:not(:last-child) {
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #eee;
}
