.playlists {
    min-height: calc(100vh - 17rem);
    max-height: calc(100vh - 17rem);
    height: calc(100vh - 17rem);
    overflow: auto;
}

.playlists > div:last-child {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding-inline-start: 1.5rem;
}
