.tagView {
    display: inline-block;
    text-decoration: none;
    padding: 4px 6px;
    margin-inline-end: 5px;
    margin-bottom: 5px;
    background: #4b4b4b;
    color: #dbdbdb;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.05rem;
    border-radius: 4px;
    white-space: nowrap;
}
.tagView:hover {
    text-decoration: none;
}

.linkView {
    text-decoration: none;
    color: #66b2e6;
    display: inline-block;
}
.linkView:hover {
    text-decoration: underline;
}
