.slider {
    display: inline-block;
    position: relative;
    cursor: pointer;

    width: 100%;
    min-width: 2rem;
    height: 7px;
    color: #aaa;
    background-color: #fff;

    border: 1px solid #aaaaaa;
    border-radius: 2px;
}

.sliderHandler {
    display: inline-block;
    position: absolute;
    overflow: hidden;
    box-shadow: rgba(100, 100, 100, 0.6) 0px 1px 3px;
}

.sliderProgressBar {
    position: absolute;
    left: 0;
    background-color: #4dada8; /*#cc181e'*/
    /*transition-property: width;
    transition-duration: 0.25s;*/
}
