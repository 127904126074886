.facebookLoginStandard,
.facebookLoginIcon {
    background-color: #4267b2;
}

.facebookLoginIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.1rem;
    height: 3.1rem;
}
